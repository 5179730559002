'use client';

import { getCookie, setCookie } from 'cookies-next';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { Button } from '../ui/Button';

const COOKIE_NAME = 'cookieConsent';

const CookieConsent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const consentCookie = getCookie(COOKIE_NAME);

    if (!consentCookie) {
      setIsOpen(true);
    }
  }, []);

  const handleAccept = () => {
    setCookie(COOKIE_NAME, 'true', { maxAge: 365 * 24 * 60 * 60 });
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className='fixed z-[200] bottom-8 w-full max-w-[1000px] left-1/2 -translate-x-1/2 bg-white/90 backdrop-blur-sm shadow-md h-20 rounded-xl sm:h-max sm:py-4'>
      <div className='w-full max-w-[1232px] mx-auto flex justify-between items-center px-6 h-full sm:flex-col sm:gap-4'>
        <p className='sm:text-center'>
          Этот сайт использует файлы cookie, чтобы обеспечить вам наилучшие
          впечатления от использования нашего сайта.
        </p>
        <div className='flex gap-3 items-end'>
          <Link
            href='/privacy-policy'
            className='text-darkGray h-9 flex items-center'
          >
            Подробнее
          </Link>
          <Button
            onClick={handleAccept}
            className='w-full text-sm rounded-lg max-w-[200px] h-9'
          >
            Принять
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
